.carousel-container {
    height: 500px;
  }
  
  .carousel-container .carousel {
    height: 100%;
  }
  
  .carousel-container .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .carousel-container {
      height: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .carousel-container {
      height: 130px;
    }
  }